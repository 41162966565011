// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_App__2\\+VHF {
  display: flex;
  flex-wrap: nowrap;
}
.App_App__Main__WQ-Am {
  background-color: rgb(223.6153846154, 245.7692307692, 250.3846153846);
  padding: 2rem;
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss","webpack://./src/assets/sass/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,iBAAA;AADF;AAEE;EACE,qECOqB;EDNrB,aAAA;EACA,YAAA;AAAJ","sourcesContent":["@import \"./assets/sass/variables\";\n\n.App {\n  display: flex;\n  flex-wrap: nowrap;\n  &__Main {\n    background-color: $color-tertiary-lighter;\n    padding: 2rem;\n    flex-grow: 1;\n  }\n}\n","// COLORS\n$color-primary: #0f2167;\n$color-primary-light: lighten($color-primary, 65%);\n$color-primary-lighter: lighten($color-primary, 73%);\n$color-primary-dark: darken($color-primary, 10%);\n$color-primary-darker: darken($color-primary, 20%);\n\n$color-secondary: #1a0f66;\n$color-secondary-light: lighten($color-secondary, 25%);\n$color-secondary-lighter: lighten($color-secondary, 73%);\n\n$color-tertiary: #0f5766;\n$color-tertiary-light: lighten($color-tertiary, 25%);\n$color-tertiary-lighter: lighten($color-tertiary, 70%);\n$color-tertiary-dark: darken($color-tertiary, 9%);\n$color-tertiary-darker: darken($color-tertiary, 50%);\n\n// $color-quaternary: #0F2167;\n\n$color-black: #111;\n\n$color-grey: #b4b4b4;\n$color-grey-light: lighten($color-grey,27%);\n\n$color-white: #eee;\n\n// FONT\n$default-font-size: 1.6rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App_App__2+VHF`,
	"App__Main": `App_App__Main__WQ-Am`
};
export default ___CSS_LOADER_EXPORT___;
