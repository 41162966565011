// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignIn_signIn__-OqFM {
  height: 100%;
  display: grid;
  grid-template-columns: 1 1fr;
  align-items: center;
  background-color: rgb(237.8042372881, 240.8093220339, 252.4957627119);
  padding-top: 4rem;
  border-radius: 9px 9px 0 0;
}
.SignIn_signIn__-OqFM .SignIn_form__FLPJU {
  width: 90%;
  margin: auto;
}
.SignIn_signIn__-OqFM .SignIn_form__group__eqrdI:not(:last-child) {
  margin-bottom: 2rem;
}
.SignIn_signIn__-OqFM .SignIn_form__group__eqrdI:last-child {
  margin-bottom: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Auth/SignIn/SignIn.module.scss","webpack://./src/assets/sass/_variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,4BAAA;EACA,mBAAA;EACA,qECJsB;EDKtB,iBAAA;EACA,0BAAA;AADF;AAGE;EACE,UAAA;EACA,YAAA;AADJ;AAEI;EACE,mBAAA;AAAN;AAEI;EACE,mBAAA;AAAN","sourcesContent":["@import \"../../../../assets/sass/variables\";\n\n.signIn {\n  height: 100%;\n  display: grid;\n  grid-template-columns: 1 1fr;\n  align-items: center;\n  background-color: $color-primary-lighter;\n  padding-top: 4rem;\n  border-radius: 9px 9px 0 0;\n\n  .form {\n    width: 90%;\n    margin: auto;\n    &__group:not(:last-child) {\n      margin-bottom: 2rem;\n    }\n    &__group:last-child {\n      margin-bottom: 4rem;\n    }\n  }\n}\n","// COLORS\n$color-primary: #0f2167;\n$color-primary-light: lighten($color-primary, 65%);\n$color-primary-lighter: lighten($color-primary, 73%);\n$color-primary-dark: darken($color-primary, 10%);\n$color-primary-darker: darken($color-primary, 20%);\n\n$color-secondary: #1a0f66;\n$color-secondary-light: lighten($color-secondary, 25%);\n$color-secondary-lighter: lighten($color-secondary, 73%);\n\n$color-tertiary: #0f5766;\n$color-tertiary-light: lighten($color-tertiary, 25%);\n$color-tertiary-lighter: lighten($color-tertiary, 70%);\n$color-tertiary-dark: darken($color-tertiary, 9%);\n$color-tertiary-darker: darken($color-tertiary, 50%);\n\n// $color-quaternary: #0F2167;\n\n$color-black: #111;\n\n$color-grey: #b4b4b4;\n$color-grey-light: lighten($color-grey,27%);\n\n$color-white: #eee;\n\n// FONT\n$default-font-size: 1.6rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signIn": `SignIn_signIn__-OqFM`,
	"form": `SignIn_form__FLPJU`,
	"form__group": `SignIn_form__group__eqrdI`
};
export default ___CSS_LOADER_EXPORT___;
