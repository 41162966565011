// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_Auth__YOGm9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Auth_Auth__YOGm9 > * {
  flex-basis: 0;
}

.Auth_signUpWrapper__P1aro {
  padding: 3rem 5%;
  border-radius: 0 0 9px 9px;
  background-color: rgb(8.5169491525, 18.7372881356, 58.4830508475);
}
.Auth_signUpWrapper__P1aro > * {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Auth/Auth.module.scss","webpack://./src/assets/sass/_variables.scss"],"names":[],"mappings":"AAEA;EAKI,aAAA;EACA,sBAAA;EACA,uBAAA;AALJ;AAMI;EACI,aAAA;AAJR;;AAOA;EACI,gBAAA;EACA,0BAAA;EACA,iECbiB;ADSrB;AAKI;EACI,WAAA;AAHR","sourcesContent":["@import \"../../../assets/sass/variables\";\n\n.Auth {\n    // background-color: orangered;\n    // display: grid;\n    // grid-template-rows: repeat(2, minmax(min-content, 1fr));\n    // height: min-content;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    & > * {\n        flex-basis: 0;\n    }\n}\n.signUpWrapper {\n    padding: 3rem 5%;\n    border-radius: 0 0 9px 9px;\n    background-color: $color-primary-dark;\n    & > * {\n        width: 100%;\n    }\n}\n","// COLORS\n$color-primary: #0f2167;\n$color-primary-light: lighten($color-primary, 65%);\n$color-primary-lighter: lighten($color-primary, 73%);\n$color-primary-dark: darken($color-primary, 10%);\n$color-primary-darker: darken($color-primary, 20%);\n\n$color-secondary: #1a0f66;\n$color-secondary-light: lighten($color-secondary, 25%);\n$color-secondary-lighter: lighten($color-secondary, 73%);\n\n$color-tertiary: #0f5766;\n$color-tertiary-light: lighten($color-tertiary, 25%);\n$color-tertiary-lighter: lighten($color-tertiary, 70%);\n$color-tertiary-dark: darken($color-tertiary, 9%);\n$color-tertiary-darker: darken($color-tertiary, 50%);\n\n// $color-quaternary: #0F2167;\n\n$color-black: #111;\n\n$color-grey: #b4b4b4;\n$color-grey-light: lighten($color-grey,27%);\n\n$color-white: #eee;\n\n// FONT\n$default-font-size: 1.6rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Auth": `Auth_Auth__YOGm9`,
	"signUpWrapper": `Auth_signUpWrapper__P1aro`
};
export default ___CSS_LOADER_EXPORT___;
