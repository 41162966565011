// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Create_PositionSubmission__AG3jS {
  width: 60rem;
}
.Create_PositionSubmission__TopInfo__UWyxT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 2rem 0;
}
.Create_PositionSubmission__TopInfo__Buttons__Z19Hd {
  display: flex;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Member/Volunteer/Create/Create.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,qBAAA;AAEN;AAAM;EACE,aAAA;EACA,SAAA;AAER","sourcesContent":[".PositionSubmission {\n    width: 60rem;\n    &__TopInfo {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      margin: 1rem 0 2rem 0;\n  \n      &__Buttons {\n        display: flex;\n        gap: 1rem;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PositionSubmission": `Create_PositionSubmission__AG3jS`,
	"PositionSubmission__TopInfo": `Create_PositionSubmission__TopInfo__UWyxT`,
	"PositionSubmission__TopInfo__Buttons": `Create_PositionSubmission__TopInfo__Buttons__Z19Hd`
};
export default ___CSS_LOADER_EXPORT___;
