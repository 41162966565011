// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Volunteer_grid__tphnQ {
  margin: 1rem 0;
  height: 80vh;
}

.Volunteer_editGrid__4VKiF {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.Volunteer_editModal__CTo\\+h {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.Volunteer_editModal__Buttons__Ol3KO {
  display: flex;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Member/Volunteer/Volunteer.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;AACF;;AAEA;EAEE,aAAA;EACA,SAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;AAAF;AAEE;EACE,aAAA;EACA,SAAA;AAAJ","sourcesContent":[".grid {\n  margin: 1rem 0;\n  height: 80vh;\n}\n\n.editGrid {\n  // background-color: orangered;\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n}\n\n.editModal {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 1rem;\n\n  &__Buttons {\n    display: flex;\n    gap: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `Volunteer_grid__tphnQ`,
	"editGrid": `Volunteer_editGrid__4VKiF`,
	"editModal": `Volunteer_editModal__CTo+h`,
	"editModal__Buttons": `Volunteer_editModal__Buttons__Ol3KO`
};
export default ___CSS_LOADER_EXPORT___;
