// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Events_editGrid__kqGTy {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.Events_grid__cNzos {
  margin: 1rem 0;
  height: 80vh;
}

.Events_editModal__9hbSz {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.Events_editModal__Buttons__GpQB- {
  display: flex;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Member/Events/Events.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,SAAA;EACA,mBAAA;AAAF;;AAGA;EACE,cAAA;EACA,YAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;AAAF;AAEE;EACE,aAAA;EACA,SAAA;AAAJ","sourcesContent":[".editGrid {\n  // background-color: orangered;\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n}\n\n.grid {\n  margin: 1rem 0;\n  height: 80vh;\n}\n\n.editModal {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 1rem;\n\n  &__Buttons {\n    display: flex;\n    gap: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editGrid": `Events_editGrid__kqGTy`,
	"grid": `Events_grid__cNzos`,
	"editModal": `Events_editModal__9hbSz`,
	"editModal__Buttons": `Events_editModal__Buttons__GpQB-`
};
export default ___CSS_LOADER_EXPORT___;
