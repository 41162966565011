// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Create_EventSubmission__Kvd0H {
  width: 60rem;
}
.Create_EventSubmission__TopInfo__ivtTw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 2rem 0;
}
.Create_EventSubmission__TopInfo__Buttons__6CGrT {
  display: flex;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Member/Events/Create/Create.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,qBAAA;AAEJ;AAAI;EACE,aAAA;EACA,SAAA;AAEN","sourcesContent":[".EventSubmission {\n  width: 60rem;\n  &__TopInfo {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 1rem 0 2rem 0;\n\n    &__Buttons {\n      display: flex;\n      gap: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EventSubmission": `Create_EventSubmission__Kvd0H`,
	"EventSubmission__TopInfo": `Create_EventSubmission__TopInfo__ivtTw`,
	"EventSubmission__TopInfo__Buttons": `Create_EventSubmission__TopInfo__Buttons__6CGrT`
};
export default ___CSS_LOADER_EXPORT___;
